import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

function Card({ title, image, body, link }) {
  return (
    <div className="card">
      <div className="card-image">
        <figure className="image is-16by9 has-background-light">
          <Link to={link}>
            <img src={image} style={{ objectFit: 'cover' }} />
          </Link>
        </figure>
      </div>
      <div className="card-content">
        <div className="content">
          <p className="heading is-size-4">{title}</p>
          <p>{body}</p>
          <br />
          <p>
            <Link to={link} className="button is-primary is-outlined is-uppercase ">
              Read More
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

Card.propTypes = {};

export default Card;
