import React from 'react';
import { graphl, Link } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import Hero from '../components/hero';
import LogoFull from '../components/logoFull';
import Portrait from '../components/portrait';
import SEO from '../components/seo';
import Card from '../components/card';

function IssuesPage({ data }) {
  const rendered = data.allContentfulIssue.edges.map(
    ({
      node: {
        id,
        title,
        slug,
        header,
        excerpt
      },
    }) => {
      return (
        <Card key={id} title={title} body={excerpt && excerpt.excerpt} image={header && header.fixed.src} link={`issue/${slug}`} />
      );
    },
  );
  return (
    <Layout>
      <SEO title="Priorities" />

      <Hero color="light" title="My Priorities" centered />

      <main className="section">
        <div className="container ">
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(min(25%, 100%), 1fr))',
              gridColumnGap: 30,
              gridRowGap: 30,
            }}
          >
            {rendered}
          </div>
        </div>
      </main>
    </Layout>
  );
}

IssuesPage.propTypes = {};

export const query = graphql`
  query {
    allContentfulIssue {
      edges {
        node {
          id
          title
          slug
          header {
            id
            title
            description
            fixed(width: 600, height: 600) {
              src
            }
          }
          excerpt {
            excerpt
          }
        }
      }
    }
  }
`;
export default IssuesPage;
